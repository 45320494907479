var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center justify-space-around"},[_c('div',{staticClass:"form-content text-center"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"disable-sort":true,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":"9999"},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('ColumnBoolean',{attrs:{"value":item.isActive,"textTrue":"Active","textFalse":"Inactive"}})]}},{key:"item.institutionLogo",fn:function(ref){
var item = ref.item;
return [_c('div',[(_vm.replaceLogoUrl)?_c('v-img',{staticClass:"mt-3 ml-auto",attrs:{"max-height":"100","max-width":"100","src":_vm.replaceLogoUrl}}):_vm._e()],1),_c('div',[(!_vm.replaceLogoUrl)?_c('v-img',{staticClass:"mt-3 ml-auto",attrs:{"max-height":"100","max-width":"100","src":_vm.logoUrl ? _vm.logoUrl : item.logoUrl}}):_vm._e()],1),((item.logoUrl || _vm.logoUrl) && _vm.schoolLogo)?_c('div',[_c('v-btn',{staticClass:"my-1",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.replaceLogo()}}},[_vm._v("Replace")])],1):_c('div',{staticClass:"text-right"},[_c('LogoField',{attrs:{"callbackSuccess":_vm.callbackSuccess,"callbackPreview":_vm.callbackPreview}})],1)]}},{key:"item.timestampUpdated",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampUpdated).format("DD MMM YYYY hh:mm A")))])]}},{key:"item.timestampCreated",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampCreated).format("DD MMM YYYY hh:mm A")))])]}},{key:"item.classroomName",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.Classroom.name))])]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$_.find(_vm.datasetCountry, { code: item.countryCode }).name)+" ")])]}},{key:"item.schoolYear",fn:function(ref){
var item = ref.item;
return [(item.schoolYear)?_c('div',[_vm._v(" "+_vm._s(_vm.$_.find(_vm.datasetSchoolYears, { id: item.schoolYear }).name)+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.month",fn:function(ref){
var item = ref.item;
return [(item.month)?_c('div',[_vm._v(" "+_vm._s(_vm.$_.find(_vm.datasetMonths, { id: item.month }).name)+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.mochiId",fn:function(ref){
var item = ref.item;
return [(item.mochiId)?_c('div',[_vm._v(" "+_vm._s(item.mochiId)+" "),_c('v-btn',{attrs:{"icon":"","color":"primary","elevation":"0","disabled":_vm.copied && _vm.copiedType == 'mochiId'},on:{"click":function($event){return _vm.copy(item.mochiId, 'mochiId')}}},[(_vm.copied && _vm.copiedType == 'mochiId')?_c('span',[_c('v-icon',[_vm._v(" mdi-check")])],1):_c('span',[_c('v-icon',[_vm._v(" mdi-content-copy")])],1)])],1):_c('div',[_vm._v("-")])]}},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [(item.avatar)?_c('AAvatar',{attrs:{"width":80,"height":80,"avatar":item.avatar}}):_c('v-img',{attrs:{"max-height":80,"max-width":80,"src":require('@/assets/CS0040_default_F0.png')}})]}},{key:"item.userName",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.User.name)+" ")])]}},{key:"item.userType",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$_ucfirst(item.type))+" Account")])]}},{key:"item.userTimestampUpdated",fn:function(ref){
var item = ref.item;
return [(item.User)?_c('span',[_vm._v(_vm._s(_vm.$moment(item.User.timestampUpdated).format("DD MMM YYYY hh:mm A")))]):_vm._e()]}},{key:"item.joinedStatus",fn:function(ref){
var item = ref.item;
return [_c('div',[(
                item.User &&
                item.User.Account &&
                item.User.Account.InvitedAccount[0] &&
                !item.User.Account.InvitedAccount[0].timestampPasswordChanged
              )?_c('span',{staticClass:"pl-2"},[_c('ColumnInviteMenu',{attrs:{"password":item.User.Account.InvitedAccount[0].password,"mobile":item.User.Account.mobile}})],1):(
                item.User &&
                item.User.Account &&
                item.User.Account.InvitedAccount[0]
              )?_c('span',[_vm._v(_vm._s(_vm.$moment( item.User.Account.InvitedAccount[0].timestampPasswordChanged ).format("DD MMM YYYY hh:mm A")))]):(
                item.User &&
                item.User.Account &&
                !item.User.Account.InvitedAccount[0]
              )?_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampCreated).format("DD MMM YYYY hh:mm A")))]):_c('span',[_vm._v("-")])])]}},{key:"item.firstSchoolDate",fn:function(ref){
              var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$_.find(_vm.datasetMonths, { id: item.month }).name)+", "+_vm._s(item.year)+" ")])]}},{key:"item.enablePIN",fn:function(ref){
              var item = ref.item;
return [_c('div',[_c('v-switch',{attrs:{"label":" "},on:{"change":function($event){return _vm.enablePassword(item)}},model:{value:(item.isEnabledPIN),callback:function ($$v) {_vm.$set(item, "isEnabledPIN", $$v)},expression:"item.isEnabledPIN"}})],1)]}},{key:"item.PIN",fn:function(ref){
              var item = ref.item;
return [(item.isEnabledPIN && !_vm.isLoading)?_c('div',[(!_vm.isEditPIN)?_c('div',[_vm._v(" "+_vm._s(item.PIN)+" "),_c('v-btn',{attrs:{"icon":"","color":"primary","elevation":"0","disabled":_vm.copied && _vm.copiedType == 'PIN'},on:{"click":function($event){return _vm.copy(item.PIN, 'PIN')}}},[(_vm.copied && _vm.copiedType == 'PIN')?_c('span',[_c('v-icon',[_vm._v(" mdi-check")])],1):_c('span',[_c('v-icon',[_vm._v(" mdi-content-copy")])],1)]),_c('v-btn',{attrs:{"icon":"","color":"primary","elevation":"0"},on:{"click":function($event){return _vm.editPIN()}}},[_c('span',[_c('v-icon',[_vm._v(" mdi-pencil")])],1)])],1):_c('div',[_c('div',{staticClass:"d-flex"},[_c('v-form',{ref:"formPIN",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"rules":_vm.pinRules,"required":"","dense":""},model:{value:(item.PIN),callback:function ($$v) {_vm.$set(item, "PIN", $$v)},expression:"item.PIN"}})],1),_c('v-btn',{attrs:{"icon":"","color":"primary","elevation":"0"},on:{"click":function($event){return _vm.submitPIN(item)}}},[_c('span',[_c('v-icon',[_vm._v(" mdi-check")])],1)])],1)])]):(_vm.isLoading)?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('div',[_vm._v("-")])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }