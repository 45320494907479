<template>
  <div>
    <div class="d-flex align-center justify-space-around">
      <div class="form-content text-center">
        <v-data-table
          :headers="headers"
          :disable-sort="true"
          :items="items"
          hide-default-footer
          class="elevation-0"
          mobile-breakpoint="9999"
        >
          <template v-slot:item.isActive="{ item }">
            <ColumnBoolean
              :value="item.isActive"
              textTrue="Active"
              textFalse="Inactive"
            ></ColumnBoolean>
          </template>
          <template v-slot:item.institutionLogo="{ item }">
            <div>
              <v-img
                v-if="replaceLogoUrl"
                max-height="100"
                max-width="100"
                :src="replaceLogoUrl"
                class="mt-3 ml-auto"
              ></v-img>
            </div>
            <div>
              <v-img
                v-if="!replaceLogoUrl"
                max-height="100"
                max-width="100"
                :src="logoUrl ? logoUrl : item.logoUrl"
                class="mt-3 ml-auto"
              ></v-img>
            </div>
            <div v-if="(item.logoUrl || logoUrl) && schoolLogo">
              <v-btn text color="primary" @click="replaceLogo()" class="my-1"
                >Replace</v-btn
              >
            </div>
            <div class="text-right" v-else>
              <LogoField
                :callbackSuccess="callbackSuccess"
                :callbackPreview="callbackPreview"
              ></LogoField>
            </div>
          </template>
          <template v-slot:item.timestampUpdated="{ item }">
            <span>{{
              $moment(item.timestampUpdated).format("DD MMM YYYY hh:mm A")
            }}</span>
          </template>
          <template v-slot:item.timestampCreated="{ item }">
            <span>{{
              $moment(item.timestampCreated).format("DD MMM YYYY hh:mm A")
            }}</span>
          </template>
          <template v-slot:item.classroomName="{ item }">
            <div>{{ item.Classroom.name }}</div>
          </template>
          <template v-slot:item.country="{ item }">
            <div>
              {{ $_.find(datasetCountry, { code: item.countryCode }).name }}
            </div>
          </template>
          <!--BOC:[dataset related columns]-->
          <template v-slot:item.schoolYear="{ item }">
            <div v-if="item.schoolYear">
              {{ $_.find(datasetSchoolYears, { id: item.schoolYear }).name }}
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:item.month="{ item }">
            <div v-if="item.month">
              {{ $_.find(datasetMonths, { id: item.month }).name }}
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:item.mochiId="{ item }">
            <div v-if="item.mochiId">
              {{ item.mochiId }}

              <v-btn
                icon
                color="primary"
                elevation="0"
                :disabled="copied && copiedType == 'mochiId'"
                @click="copy(item.mochiId, 'mochiId')"
              >
                <span v-if="copied && copiedType == 'mochiId'"
                  ><v-icon> mdi-check</v-icon></span
                >
                <span v-else><v-icon> mdi-content-copy</v-icon></span>
              </v-btn>
            </div>
            <div v-else>-</div>
          </template>
          <!--EOC-->
          <template v-slot:item.avatar="{ item }">
            <AAvatar
              v-if="item.avatar"
              :width="80"
              :height="80"
              :avatar="item.avatar"
            />
            <v-img
              v-else
              :max-height="80"
              :max-width="80"
              :src="require('@/assets/CS0040_default_F0.png')"
            ></v-img>
          </template>
          <template v-slot:item.userName="{ item }">
            <div>
              {{ item.User.name }}
            </div>
          </template>
          <template v-slot:item.userType="{ item }">
            <div>{{ $_ucfirst(item.type) }} Account</div>
          </template>
          <template v-slot:item.userTimestampUpdated="{ item }">
            <span v-if="item.User">{{
              $moment(item.User.timestampUpdated).format("DD MMM YYYY hh:mm A")
            }}</span>
          </template>
          <template v-slot:item.joinedStatus="{ item }">
            <div>
              <span
                v-if="
                  item.User &&
                  item.User.Account &&
                  item.User.Account.InvitedAccount[0] &&
                  !item.User.Account.InvitedAccount[0].timestampPasswordChanged
                "
                class="pl-2"
                ><ColumnInviteMenu
                  :password="item.User.Account.InvitedAccount[0].password"
                  :mobile="item.User.Account.mobile"
                ></ColumnInviteMenu
              ></span>
              <span
                v-else-if="
                  item.User &&
                  item.User.Account &&
                  item.User.Account.InvitedAccount[0]
                "
                >{{
                  $moment(
                    item.User.Account.InvitedAccount[0].timestampPasswordChanged
                  ).format("DD MMM YYYY hh:mm A")
                }}</span
              >
              <span
                v-else-if="
                  item.User &&
                  item.User.Account &&
                  !item.User.Account.InvitedAccount[0]
                "
                >{{
                  $moment(item.timestampCreated).format("DD MMM YYYY hh:mm A")
                }}</span
              >
              <span v-else>-</span>
            </div>
          </template>
          <template v-slot:item.firstSchoolDate="{ item }">
            <div>
              {{ $_.find(datasetMonths, { id: item.month }).name }},
              {{ item.year }}
            </div>
          </template>

          <template v-slot:item.enablePIN="{ item }">
            <div>
              <v-switch
                v-model="item.isEnabledPIN"
                label=" "
                @change="enablePassword(item)"
              ></v-switch>
            </div>
          </template>
          <template v-slot:item.PIN="{ item }">
            <div v-if="item.isEnabledPIN && !isLoading">
              <div v-if="!isEditPIN">
                {{ item.PIN }}
                <v-btn
                  icon
                  color="primary"
                  elevation="0"
                  :disabled="copied && copiedType == 'PIN'"
                  @click="copy(item.PIN, 'PIN')"
                >
                  <span v-if="copied && copiedType == 'PIN'"
                    ><v-icon> mdi-check</v-icon></span
                  >
                  <span v-else><v-icon> mdi-content-copy</v-icon></span>
                </v-btn>
                <v-btn icon color="primary" elevation="0" @click="editPIN()">
                  <span><v-icon> mdi-pencil</v-icon></span>
                </v-btn>
              </div>
              <div v-else>
                <div class="d-flex">
                  <v-form ref="formPIN" v-model="valid" lazy-validation>
                    <v-text-field
                      v-model="item.PIN"
                      :rules="pinRules"
                      required
                      dense
                    ></v-text-field>
                  </v-form>
                  <v-btn
                    icon
                    color="primary"
                    elevation="0"
                    @click="submitPIN(item)"
                  >
                    <span><v-icon> mdi-check</v-icon></span>
                  </v-btn>
                </div>
              </div>
            </div>
            <div v-else-if="isLoading">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <div v-else>-</div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import datasetCountry from "@/datasets/country";
import datasetSchoolYears from "@/datasets/schoolYear";
import datasetMonths from "@/datasets/month";
import LogoField from "../Form/LogoField.vue";
import ColumnBoolean from "./ColumnBoolean.vue";
import ColumnInviteMenu from "./ColumnInviteMenu.vue";
export default {
  components: {
    LogoField,
    ColumnBoolean,
    ColumnInviteMenu,
    //
  },
  computed: {
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["data", "headers", "callbackChange", "callbackEdit"],
  data: () => ({
    logoUrl: null,
    replaceLogoUrl: null,
    schoolLogo: true,
    datasetCountry: datasetCountry,
    datasetSchoolYears: datasetSchoolYears,
    datasetMonths: datasetMonths,
    pageCount: 0,
    itemsPerPage: 10,
    isLoading: false,
    copied: false,
    copiedType: null,
    isEditPIN: false,
    pinRules: [],
    valid: true,
    // headers: [
    //   {
    //     text: "Id",
    //     align: "start",
    //     sortable: false,
    //     value: "id",
    //   },
    //   { text: "Name", value: "name" },
    //   { text: "Last Update", value: "timestampUpdated" },
    // ],
    items: [],
  }),
  created() {
    this.items.push(this.data);
  },
  mounted() {},
  watch: {
    data: function () {
      this.items = [];
      this.items.push(this.data);
      this.isLoading = false;
    },
  },
  methods: {
    callbackSuccess(res) {
      this.replaceLogoUrl = null;
      this.schoolLogo = true;
      this.logoUrl = res.data.logoUrl;
    },
    replaceLogo() {
      //this.logoUrl=null
      this.replaceLogoUrl = null;
      this.schoolLogo = false;
    },

    enablePassword(item) {
      this.isLoading = true;
      this.callbackChange(item);
    },
    editPIN() {
      this.isEditPIN = true;
    },
    submitPIN(item) {
      this.pinRules = [
        (v) => /^\d{4}$/.test(v) || "PIN must be a 4-digit number",
      ];
      let self = this;
      setTimeout(function () {
        if (self.$refs.formPIN.validate()) {
          self.isLoading = true;
          self.callbackEdit(item);
        self.isEditPIN = false;
        }
      });
    },
    callbackPreview(url) {
      this.replaceLogoUrl = url;
    },
    copy(item, type) {
      this.copiedType = type;
      navigator.clipboard.writeText(item);
      this.copied = true;
      var that = this;
      setTimeout(() => {
        if (that && that.copied) that.copied = false;
      }, 800);
    },
  },
};
</script>